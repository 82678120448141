import React, { useContext } from "react";
import SummerKindnessSeriesLayout from "@src/layouts/SummerKindnessSeriesLayout";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import SummerKindnessModalContent from "@src/components/partials/summer-kindness-series/SummerKindnessModalContent";
import window from "global/window";

function SummerKindnessSeriesWeek4() {
  const data = useStaticQuery(graphql`
    {
      headerImage: file(name: { eq: "mojo-week-4" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      activity1: file(name: { eq: "StoryWalkIcon" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      activity2: file(name: { eq: "MOJOandtheMAZE" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 80, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);

  const { headerImage, activity1, activity2 } = data;
  const modalContext = useContext(ModalContext);
  return (
    <>
      <SEO
        title="The Summer Kindness Series - Week 4"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series - Week 4",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <SummerKindnessSeriesLayout
        header="Stretch Your Imagination"
        headerSub="Kindness Habit 4:"
        headerText={
          <>
            Get inspired by <br />
            great stories
          </>
        }
        whatisIt="July trip to Krypton not fitting into your summer vacation plans? Never fear, stories are here! When kids read stories about characters facing relatable dilemmas they learn how to empathize with those around them. Even better, relatable dilemmas can happen in even the most un-relatable places! So hop on that story-rocket. A galaxy far away is closer than you think!"
        whyIsItImportant="Think back to your own childhood and your favorite stories. Chances are you don’t just remember the story, you remember how the story made you feel. It takes a lot of imagination to see the world from someone else’s eyes. The more kids use their imagination, the more they learn how to relate to people in the real world too. You know, the one here on Earth. For now at least :)"
        headerImage={headerImage}
        habbits={[
          {
            header: "Create a daily routine:",
            text: "Carve out time in your family's busy schedule for some reading fun! Start with 5 minutes a day and see what happens!",
          },
          {
            header: "Pause and probe:",
            text: "“What would you do if you were her? What do you think would make him feel better?” Take a moment to pause the story and ask questions about characters’ thoughts, emotions and desires.",
          },
          {
            header: "Delight with discovery:",
            text: "Kids say their favorite books are usually the ones they pick out themselves! Go on a treasure hunt to the library or bookstore this weekend :)",
          },
          {
            header: "Enjoy stories, together:",
            text: "From family book clubs to movie nights, start a story sharing tradition. Popcorn optional, but suggested :)",
          },
        ]}
        activitiesText="Stretch Your Imagination at home :) These are ideas to inspire new traditions. Did you know when kids are asked to teach it makes ideas more
              memorable?"
        activities={[
          {
            icon: activity1,
            header: "Story Walk",
            text: "A new way to look at our favorite stories!",
            onClick: () => {
              modalContext.showModal(ModalType.SummerKindnessModal, {
                content: (
                  <SummerKindnessModalContent
                    header1="Story Walk"
                    text1="What are your favorite stories? Get inspired by tales old and new, and learn a fun new way to experience your favorite picture books! Watch the video for instructions!"
                    text2={
                      <>
                        Activity Type: Family Time
                        <br />
                        Play Time: 20 min to read and discuss!
                      </>
                    }
                    header2="Stretch Your Imagination"
                    items={[
                      { youtubeUrl: "https://www.youtube.com/embed/5PUweBRO0og" },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Week4_Story_Walk.pdf",
                        text: "Activity PDF",
                        icon: "pdf",
                      },
                      {
                        href: "https://static.classdojo.com/img/2018-summer_kindness/Empathy-Book-List.pdf",
                        text: "Kindness Book List",
                        icon: "template",
                      },
                    ]}
                  />
                ),
              });
            },
          },
          {
            icon: activity2,
            header: "Mojo and The Maze",
            text: "Watch these videos and discuss!",
            onClick: () => {
              window.location.href = "https://static.classdojo.com/img/2018-summer_kindness/Week4_EmpathyVideos.pdf";
            },
          },
        ]}
      />
    </>
  );
}

export default SummerKindnessSeriesWeek4;
